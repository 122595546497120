<template>
    <div class="relative mb-4">
        <div class="flex items-center">
            <div
                class="w-7 h-7 border rounded-small cursor-pointer mr-3 flex justify-center items-center shrink-0"
                @click.prevent="toggle"
                @keydown="onKeydown"
                tabindex="0"
                :class="{
                    'border-green-default': localValue,
                    'border-red-default': showError()
                }"
            >
                <Transition name="fade">
                    <CheckmarkIcon v-if="localValue"/>
                </Transition>
            </div>
            <label
                :for="inputId"
                class="text-xs"
                :class="{
                    'text-red-default': showError()
                }"
            >
                <slot name="label"></slot>
            </label>
            <input
                type="checkbox"
                ref="checkbox"
                class="sr-only"
                tabindex="-1"
                :id="inputId"
                :value="localValue"
                :required="required"
                :checked="localValue"
            />
        </div>

        <div class="text-red-default text-xs mt-2" v-if="showError() && $slots.error">
            <slot name="error"></slot>
        </div>
    </div>
</template>

<script>
import CheckmarkIcon from '@/../svg/checkmark-bold.svg?component';

export default {
    data() {
        return {
            dirty: false,
            localValue: false,
        };
    },
    components: {
        CheckmarkIcon
    },
    mounted() {
    },
    props: {
        required: Boolean,
        valid: Boolean,
        value: Boolean,
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        onKeydown(event) {
            if ([13, 32].includes(event.keyCode)) {
                this.toggle();
            }
        },

        toggle() {
            if (this.disabled) return;
            this.localValue = !this.localValue;
            this.dirty = true;
            this.$emit('update:modelValue', this.localValue);
        },

        showError() {
            if (!this.dirty) {
                return false;
            }
            return !this.valid;
        }
    },
    created() {
        this.localValue = this.value || false;
    },
    computed: {
        inputId() {
            const uuid = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
            return uuid;
        }
    },
    watch: {
        localValue(newVal, oldVal) {
            this.localValue = newVal;
        },
        value(newVal, oldVal) {
            this.localValue = newVal;
        }
    }
};
</script>

<style lang="postcss" scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: all .15s ease
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0
    }
</style>
