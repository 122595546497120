<template>
    <div>
        <div class="text-left mt-2">
            <div class="flex items-center text-sm">
                <ul>
                    <li class="text-xs" :class="rules.uppercase ? 'text-green-default' : 'text-red-default'"><Component :is="rules.uppercase ? 'Checkmark' : 'Add'" class="inline scale-90"/> One uppercase letter</li>
                    <li class="text-xs" :class="rules.lowercase ? 'text-green-default' : 'text-red-default'"><Component :is="rules.lowercase ? 'Checkmark' : 'Add'" class="inline scale-90"/> One lowercase letter</li>
                    <li class="text-xs" :class="rules.number ? 'text-green-default' : 'text-red-default'"><Component :is="rules.number ? 'Checkmark' : 'Add'" class="inline scale-90"/> One number</li>
                    <li class="text-xs" :class="rules.characterMin ? 'text-green-default' : 'text-red-default'"><Component :is="rules.characterMin ? 'Checkmark' : 'Add'" class="inline scale-90"/> 7 characters minimum</li>
                    <li class="text-xs" :class="rules.characterMax ? 'text-green-default' : 'text-red-default'"><Component :is="rules.characterMax ? 'Checkmark' : 'Add'" class="inline scale-90"/> 255 characters maximum</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Checkmark from '@/../svg/checkmark.svg?component';
import Add from '@/../svg/add.svg?component';

export default {
    data() {
        return {
            rules: {
                uppercase: false,
                lowercase: false,
                number: false,
                characterMin: false,
                characterMax: true,
            }
        };
    },
    components: {
        Checkmark,
        Add,
    },
    mounted() {
        this.validatePassword();
    },
    props: {
        disabled: Boolean,
        arrow: Boolean,
    },
    methods: {
        passwordIsValid() {
            this.$store.commit('setPasswordValid', (this.rules.uppercase && this.rules.lowercase && this.rules.number && this.rules.characterMin && this.rules.characterMax));
        },
        validatePassword() {
            if (/[A-Z]/.test(this.password)) {
                this.rules.uppercase = true;
            } else {
                this.rules.uppercase = false;
            }

            // Check if password contains a lowercase letter
            if (/[a-z]/.test(this.password)) {
                this.rules.lowercase = true;
            } else {
                this.rules.lowercase = false;
            }

            // Check if password contains a number
            if (/\d/.test(this.password)) {
                this.rules.number = true;
            } else {
                this.rules.number = false;
            }

            // Check if password meets the minimum character requirement
            if (this.password.length >= 7) {
                this.rules.characterMin = true;
            } else {
                this.rules.characterMin = false;
            }

            // Check if password exceeds the maximum character limit
            if (this.password.length <= 255) {
                this.rules.characterMax = true;
            } else {
                this.rules.characterMax = false;
            }
            this.passwordIsValid();
        }
    },
    created() {
    },
    computed: {
        password() {
            return this.$store.getters.password;
        }
    },
    watch: {
        async password(newVal, oldVal) {
            // Check if password contains an uppercase letter, a lowercase letter, and a number, and that it's 7-255 chars long
            this.validatePassword();
        }
    }
};
</script>

<style lang="postcss">
    .text-red-default {
        > svg {
            @apply rotate-45;
            path {
                @apply fill-red-default;
            }
        }
    }
    .text-green-default {
        > svg path {
            @apply fill-green-default;
        }
    }
</style>
