<template>
    <Modal small ref="modal" qa-name="payment-processing-modal">
        <div class="flex items-center flex-col">
            <h2 class="font-bold text-center font-walsheim text-2xl mb-8 leading-none">System Maintenance</h2>
            <p class="text-sm">We're currently performing system maintenance. Please contact your referring Keap partner for automations that weren't pre-installed in your app, or reach out to Keap Support at <a class="underline" href="https://keap.com/contact">https://keap.com/contact</a>.</p>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/Components/Modal.vue';

export default {
    components: {
        Modal,
    },
    mounted() {
        this.$refs.modal.openModal();
    },
};
</script>

<style lang="scss" scoped>
</style>
