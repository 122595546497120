<template>
    <div class="field-wrapper" :class="showError() ? 'field-wrapper--error' : ''">
        <label :for="inputId" class="field-label" :class="isFocused || localValue != '' ? 'field-label--up' : ''">
            {{ fieldLabel }}<span v-if="required" class="text-red-default">&nbsp;*</span>
        </label>
        <input
            :type="fieldType"
            name=""
            class="field-input"
            :class="showError() ? 'mb-1' : ''"
            :id="inputId"
            :value="localValue"
            :required="required"
            @blur="onBlur"
            @focus="onFocus"
            @input="updateInput($event.target.value)"
        />
        <div class="text-red-default text-xs" v-if="showError() && $slots.error">
            <slot name="error"></slot>
        </div>
    </div>
</template>

<script>
import { AsYouType } from 'libphonenumber-js/min';

export default {
    components: {
    },
    data() {
        return {
            isFocused: false,
            localValue: '',
            dirty: false,
        };
    },
    mounted() {
        this.asYouType = new AsYouType(this.stateCountry || 'US');
    },
    props: {
        fieldLabel: String,
        fieldType: String,
        required: Boolean,
        valid: Boolean,
        value: String,
    },
    methods: {
        onBlur() {
            this.isFocused = false;

            if (this.localValue.length > 0) {
                this.dirty = true;
            }
        },
        onFocus() {
            this.isFocused = true;
        },
        updateInput(value) {
            this.asYouType.reset();

            const displayFormat = this.asYouType.input(value);
            const phoneNumber = this.asYouType.getNumber();

            if (phoneNumber && phoneNumber.country) {
                this.countryCode = phoneNumber.country;
            }
            this.localValue = displayFormat;
            this.$emit('update:modelValue', this.localValue);
        },
        showError() {
            if (this.localValue.length <= 0 && !this.dirty) {
                return false;
            }
            if (!this.dirty) {
                return false;
            }
            return !this.valid;
        }
    },
    created() {
        this.localValue = this.value || '';
    },
    computed: {
        inputId() {
            const uuid = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
            return uuid;
        },

        countryCode: {
            get() {
                return this.$store.state.userInfo.country;
            },
            set(value) {
                this.$store.commit('updateUserInfo', { country: value });
            }
        },
    },
    watch: {
        localValue(newVal, oldVal) {
            this.localValue = newVal;
        },
        value(newVal, oldVal) {
            this.localValue = newVal;
        }
    }
};
</script>

<style lang="postcss" scoped>
</style>
