<template>
    <div class="field-wrapper" :class="showError() ? 'field-wrapper--error' : ''">
        <label :for="inputId" class="field-label" :class="isFocused || localValue != '' ? 'field-label--up' : ''">
            {{ fieldLabel }}<span v-if="required" class="text-red-default">&nbsp;*</span>
        </label>
        <div class="flex items-center" :class="showError() ? 'mb-1' : ''">
            <input
                :type="fieldType"
                name=""
                class="field-input"
                :id="inputId"
                :value="localValue"
                :required="required"
                @blur="onBlur"
                @focus="onFocus"
                @input="updateInput($event.target.value)"
            />
            <div class="absolute right-5 cursor-pointer" tabindex="0" @keydown.space="passwordVisible = !passwordVisible" @click="passwordVisible = !passwordVisible">
                <EyeShow v-if="!passwordVisible"/>
                <EyeHide v-if="passwordVisible"/>
            </div>
        </div>
        <div class="text-red-default text-xs" v-if="showError() && $slots.error">
            <slot name="error"></slot>
        </div>
        <Transition name="info-fade-in">
            <PasswordStrength v-if="localValue" ref="strength"/>
        </Transition>
    </div>
</template>

<script>
import PasswordStrength from '@/FreeTrial/Components/PasswordStrength.vue';

import EyeShow from '@/../svg/eye-show.svg?component';
import EyeHide from '@/../svg/eye-hide.svg?component';

export default {
    data() {
        return {
            isFocused: false,
            localValue: '',
            dirty: false,
            fieldType: 'password',
            passwordVisible: false,
        };
    },
    components: {
        PasswordStrength,
        EyeShow,
        EyeHide,
    },
    mounted() {
    },
    props: {
        fieldLabel: String,
        required: Boolean,
        valid: Boolean,
        value: String,
    },
    methods: {
        onBlur() {
            this.isFocused = false;

            if (this.localValue.length > 0) {
                this.dirty = true;
            }
        },
        onFocus() {
            this.isFocused = true;
        },
        updateInput(value) {
            this.localValue = value;
            this.$emit('update:modelValue', this.localValue);
        },
        showError() {
            if (this.localValue.length <= 0 && !this.dirty) {
                return false;
            }
            if (!this.dirty) {
                return false;
            }
            return !this.valid;
        }
    },
    created() {
        this.localValue = this.value || '';
    },
    computed: {
        inputId() {
            const uuid = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
            return uuid;
        }
    },
    watch: {
        localValue(newVal, oldVal) {
            this.localValue = newVal;
        },
        value(newVal, oldVal) {
            this.localValue = newVal;
        },
        passwordVisible(newVal, oldVal) {
            this.fieldType = newVal ? 'text' : 'password';
        }
    }
};
</script>

<style lang="postcss" scoped>
.field-input {
    @apply pr-12;
}

</style>
