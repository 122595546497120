import '../bootstrap';

import Pusher from 'pusher-js';

import { createApp } from 'vue';
import { ftStore } from './store';

import App from './Pages/FreeTrial.vue';
window.Pusher = Pusher;

const app = createApp(App);

// To avoid sentry hurting our web vitals we delay its load by 5 seconds
if (!window.Sentry.loaded && !!Keap.env.VITE_ENABLE_SENTRY && Keap.env.VITE_ENABLE_SENTRY.toLowerCase() === 'true') {
    setTimeout(loadSentry, 6000);
}

async function loadSentry() {
    const module = await import('@/Util/sentry.js');
    window.Sentry = new module.Sentry();
}

app.use(ftStore);

app.mount("#app");
